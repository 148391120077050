(<template>
  <div class="hotline-codes">
    <div class="hotline-codes__content">
      <div class="hotline-codes__header">
        <h2 class="hotline-codes__title">{{ $gettext('Arabic Hotline') }}</h2>
        <p class="hotline-codes__description">{{ $gettext('Get immediate access to Arabic interpreters') }}</p>
      </div>
      <div class="hotline-codes__instructions">
        <p v-for="(instruction, index) in instructions"
           :key="instruction.text"
           class="hotline-codes__instruction"
           v-html="genInstructionText(getStepText(index), instruction.text)"></p>
      </div>
      <table class="hotline-codes__codes">
        <tr v-for="code in pinCodes"
            :key="code.name"
            class="hotline-codes__codes-row">
          <td class="hotline-codes__codes-cell"
              v-html="getCodeCellText(departmentText, code.name)"></td>
          <td class="hotline-codes__codes-cell"
              v-html="getCodeCellText(pinText, code.hotlinePinCode)"></td>
        </tr>
      </table>
      <button class="sk-btn sk-btn--default hotline-codes__print-btn"
              @click="printCodes">{{ $gettext('Print out your pin nr(s)') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    asyncData({store}) {
      let promisesArr = [];
      if (store.getters['UserInfoStore/userCanSeeHotline']) {
        promisesArr = [...promisesArr, store.dispatch('TTHotlineStore/getPinCodes')];
        promisesArr = [...promisesArr, store.dispatch('TTHotlineStore/getPhones')];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    computed: {
      ...mapState('TTHotlineStore', {
        pinCodes: (state) => state.pinCodes || [],
        phones: (state) => state.phones || []
      }),
      departmentText() { return this.$gettext('Department'); },
      pinText() { return this.$gettext('Pin'); },
      arabicTranslationPhone() { return this.phones.length ? this.phones[0].number : ''; },
      instructions() {
        return [
          {text: `${this.$gettext('Call')} ${this.arabicTranslationPhone}`},
          {text: this.$gettext('Enter the correct pin')},
          {text: this.$gettext('Get connected')}
        ];
      }
    },
    methods: {
      getStepText(index) {
        return this.$gettextInterpolate(this.$gettext('Step %{n}'), {n: index + 1});
      },
      genInstructionText(step, instruction) {
        const template = this.$gettext('<b>%{step}.</b> <span>%{instruction}</span>');
        return this.$gettextInterpolate(template, {step, instruction});
      },
      getCodeCellText(name, value) {
        return this.$gettextInterpolate(this.$gettext('%{name}: <b>%{value}</b>'), {name, value});
      },
      printCodes() { window.print(); }
    }
  };
</script>

<style scoped>
  .hotline-codes {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 800px;
    margin: 0 auto;
    padding: 15px;
    font-size: 12px;
  }

  .hotline-codes__content {
    padding: 30px;
    background-color: #fff;
  }

  .hotline-codes__header {
    margin-bottom: 20px;
  }

  .hotline-codes__title {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 18px;
  }

  .hotline-codes__instructions {
    margin-bottom: 20px;
  }

  .hotline-codes__codes {
    width: 100%;
    border-collapse: collapse;
  }

  .hotline-codes__codes-cell {
    width: 50%;
    padding: 4px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .hotline-codes__print-btn {
    width: auto;
    margin-top: 20px;
    padding: 0 20px;
  }
</style>
